<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row paj-custom-row">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12
                pb-4
                wrapper-dashboard
                custom-wrapper-dashboard
              "
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default"
              >
                <div
                  class="
                    d-flex
                    align-items-center
                    flex-wrap
                    mr-2
                    text-title-point-adjustment
                  "
                >
                  <img
                    class="mr-2"
                    :src="require('@/assets/images/fil022.svg')"
                    alt=""
                  />{{ lbl['import-log-menu'] }}
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-point-adjustment">
              <div class="d-flex wrapper-select-header">
                <div @click="goto('ImportUser')" class="select-header">
                  {{ lbl['import-user-menu'] }}
                </div>
                <div class="select-header select-hd-right active">
                  {{ lbl['import-log-menu'] }}
                </div>
                <div
                  @click="goto('ImportUserSearch')"
                  class="select-header select-hd-right"
                >
                  {{ lbl['import-search-menu'] }}
                </div>
              </div>
              <div class="wrapper-content">
                <div class="wrap-table around-content-import">
                  <a-table
                    row-key="RowKey"
                    :columns="columns"
                    :data-source="data"
                    :pagination="{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                      pageSizeOptions: pageSizeOptions,
                      pageSize: defaultPageSize,
                      defaultCurrent: 1,
                      showSizeChanger: true,
                      hideOnSinglePage: false,
                      onChange: onChangePage,
                    }"
                  >
                    <template slot="date" slot-scope="text">
                      {{ dateShortFormatUTC(text) }}
                    </template>
                    <template slot="status" slot-scope="text, record">
                      {{ record.Success ? lbl['success'] : lbl['fail'] }}
                    </template>
                    <template slot="summary" slot-scope="text, record">
                      {{ record.SuccessCount + record.UpdateCount }}
                    </template>
                    <template slot="action" slot-scope="text, record">
                      <a class="a" @click="openDetail(record)">{{
                        lbl['smart-target-view']
                      }}</a>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visibleDetail"
      :title="lbl['import-log-dtl-title']"
      id="modal-import-log-detail"
      :footer="null"
      centered
      class="table-detail-importUserLog"
      :class="[currentLocale]"
    >
      <div class="filterContainer nopad mb-2">
        <div :class="['filterMain', showFilterMain ? 'active' : '']">
          <div class="filter" @click="toggle('showFilterMain')">
            {{ lbl['user-permissions-filter'] }}
            <img :src="require('@/assets/images/filter_list.png')" alt="" />
          </div>
          <div class="spaceHover"></div>
          <div class="filterDropdown">
            <div class="filterLeft">
              <div
                :class="['filterList', item === subFilter ? 'active' : '']"
                v-for="item in Object.keys(filter)"
                @click="changeSubFilter(item)"
                :key="item"
              >
                {{ lbl[filter[item].label] }}
                <img
                  :src="require('@/assets/images/navigate_next.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="filterRight">
              <div v-for="item in filter[subFilter].input" :key="item">
                <div class="filterList" @click="selectFilter(item)">
                  <CheckBox
                    :label="item"
                    :valuee="item"
                    :check="filter[subFilter].value"
                    type="filter"
                  />
                </div>
              </div>
            </div>
            <div class="btnFilter">
              <div @click="resetFilter()">
                {{ lbl['user-permissions-reset'] }}
              </div>
              <button @click="getLogImportUserDetail()">
                {{ lbl['user-permissions-ok'] }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="filterItem"
          v-for="(item, index) in Object.keys(allFilter)"
          :key="'filter' + index"
        >
          <span v-if="allFilter[item] == 'success'">
            {{ lbl['import-log-import-success'] }}
            <img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </span>
          <span v-else>
            {{ lbl['import-failed'] }}
            <img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </span>
        </div>
      </div>
      <a-table
        row-key="RowKey"
        :columns="columnsDetail"
        :data-source="dataDetail"
        :pagination="{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
          pageSizeOptions: pageSizeOptions,
          pageSize: defaultPageSize,
          defaultCurrent: 1,
          showSizeChanger: true,
          hideOnSinglePage: false,
          onChange: onChangePageDetail,
        }"
      >
        <template slot="status" slot-scope="text, record">
          {{ record.Active ? lbl['active'] : lbl['inactive'] }}
        </template>
        <template slot="isdata" slot-scope="text">
          {{ text ? text : '-' }}
        </template>
        <template slot="gender" slot-scope="text, record">
          <span v-if="record.Gender == 'male' || record.Gender == 'Male'">
            {{ lbl['Male'] }}
          </span>
          <span
            v-else-if="record.Gender == 'female' || record.Gender == 'Female'"
          >
            {{ lbl['Female'] }}
          </span>
          <span v-else>
            {{ lbl['Other'] }}
          </span>
        </template>
        <template slot="date" slot-scope="text">
          {{ text ? formatDate(text) : '-' }}
        </template>
        <template slot="is_success" slot-scope="text">
          <a-tag v-if="text" color="green">
            <span class="d-flex align-center">
              <a-icon type="check-circle" class="mr-1" />
              {{ lbl['success'] }}
            </span>
          </a-tag>
          <a-tag v-else color="red">
            <span class="d-flex align-center">
              <a-icon type="close-circle" class="mr-1" />
              {{ lbl['import-failed'] }}
            </span>
          </a-tag>
        </template>
      </a-table>
      <div class="export-file-container">
        <a-button class="btn-success" @click="exportFile('success')">
          {{ lbl['download-success-user'] }}
        </a-button>
        <a-button class="btn-fail" @click="exportFile('fail')">
          {{ lbl['download-fail-user'] }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsImport from '@/core/Import/service/BzbsImport'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'
import XLSX from 'xlsx'
import moment from 'moment'
import CheckBox from './components/CheckBox.vue'

export default {
  name: 'Log',
  mixins: [Mixin],
  components: {
    Header,
    CheckBox,
  },
  data: function () {
    return {
      currentLocale: Locale.getLocaleShort(),
      isAlreadyPoint: false,
      isAlreadyStamp: false,
      allowImportPoint: true,
      campaignmode: 'Point',
      data: [],
      total: null,
      current: 1,
      dataDetail: [],
      totalDetail: null,
      currentDetail: 1,
      visibleDetail: false,
      partionkey: '',
      FileName: '',
      pageSize: 30,
      pageSizeDetail: 30,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      subFilter: 'status',
      showFilterMain: false,
      filter: {
        status: {
          value: null,
          input: ['success', 'failed'],
          label: 'import-log-status-header',
          type: 'checkbox',
        },
      },
      allFilter: {},
      columns: [
        {
          title: 'File Name',
          dataIndex: 'FileName',
          key: 'import-log-file-name',
          width: '30%',
          align: 'left',
        },
        {
          title: 'Import date',
          dataIndex: 'Timestamp',
          key: 'import-log-import-date',
          align: 'center',
          scopedSlots: {
            customRender: 'date',
          },
        },
        {
          title: 'Imported',
          dataIndex: 'TotalCount',
          key: 'imported',
          align: 'center',
        },
        {
          title: 'Success',
          dataIndex: 'SuccessCount',
          key: 'import-log-import-success',
          align: 'center',
          scopedSlots: {
            customRender: 'summary',
          },
        },
        {
          title: 'Failed',
          dataIndex: 'FailCount',
          key: 'import-failed',
          align: 'center',
        },
        {
          title: 'Import status',
          key: 'import-log-import-status',
          dataIndex: 'Success',
          align: 'center',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '',
          align: 'center',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],

      columnsDetail: [
        {
          title: 'Status',
          dataIndex: 'Success',
          key: 'import-log-status-header',
          width: '20%',
          scopedSlots: {
            customRender: 'is_success',
          },
        },
        {
          title: 'Name',
          dataIndex: 'FirstName',
          key: 'user-permissions-name',
          width: '20%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'LastName',
          dataIndex: 'LastName',
          key: 'import-log-lastname-header',
          width: '20%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'VerifyCode1',
          dataIndex: 'VerifyCode1',
          key: 'imp-usr-dtl-verf1',
          width: '15%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'VerifyCode2',
          dataIndex: 'VerifyCode2',
          key: 'imp-usr-dtl-verf2',
          width: '15%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'BirthDate',
          dataIndex: 'BirthDate',
          key: 'imp-usr-dtl-birthday',
          width: '15%',
          scopedSlots: {
            customRender: 'date',
          },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'imp-usr-dtl-gender',
          width: '10%',
          scopedSlots: {
            customRender: 'gender',
          },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'imp-usr-dtl-email',
          width: '25%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Tel',
          dataIndex: 'Tel',
          key: 'imp-usr-dtl-tel',
          width: '15%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Active',
          dataIndex: 'Active',
          key: 'user-level-status-active',
          width: '10%',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: 'Point',
          dataIndex: 'StrPoint',
          key: 'plan-reward-point-burn-manage-point-unit',
          width: '10%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'LevelName',
          dataIndex: 'LevelName',
          key: 'imp-usr-level-header',
          width: '15%',
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'Remark',
          dataIndex: 'Message',
          key: 'remark',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
      ],
    }
  },
  watch: {
    dataDetail(value) {
      if (value.length > 0) {
        var hasVerifyCode3 = !value.every(item => item.VerifyCode3 == '')
        var hasVerifyCode4 = !value.every(item => item.VerifyCode4 == '')

        console.log('hasVerifyCode3::', hasVerifyCode3)
        console.log('hasVerifyCode4::', hasVerifyCode4)

        if (hasVerifyCode4) {
          this.columnsDetail = [
            {
              title: 'Status',
              dataIndex: 'Success',
              key: 'import-log-status-header',
              width: '20%',
              scopedSlots: {
                customRender: 'is_success',
              },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LastName',
              dataIndex: 'LastName',
              key: 'import-log-lastname-header',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode3',
              dataIndex: 'VerifyCode3',
              key: 'imp-usr-dtl-verf3',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode4',
              dataIndex: 'VerifyCode4',
              key: 'imp-usr-dtl-verf4',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'BirthDate',
              dataIndex: 'BirthDate',
              key: 'imp-usr-dtl-birthday',
              width: '15%',
              scopedSlots: {
                customRender: 'date',
              },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              width: '10%',
              scopedSlots: {
                customRender: 'gender',
              },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              width: '25%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'user-level-status-active',
              width: '10%',
              scopedSlots: {
                customRender: 'status',
              },
            },
            {
              title: 'Point',
              dataIndex: 'StrPoint',
              key: 'plan-reward-point-burn-manage-point-unit',
              width: '10%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LevelName',
              dataIndex: 'LevelName',
              key: 'imp-usr-level-header',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
          ]
        } else if (!hasVerifyCode4 && hasVerifyCode3) {
          this.columnsDetail = [
            {
              title: 'Status',
              dataIndex: 'Success',
              key: 'import-log-status-header',
              width: '20%',
              scopedSlots: {
                customRender: 'is_success',
              },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LastName',
              dataIndex: 'LastName',
              key: 'import-log-lastname-header',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode3',
              dataIndex: 'VerifyCode3',
              key: 'imp-usr-dtl-verf3',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'BirthDate',
              dataIndex: 'BirthDate',
              key: 'imp-usr-dtl-birthday',
              width: '15%',
              scopedSlots: {
                customRender: 'date',
              },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              width: '10%',
              scopedSlots: {
                customRender: 'gender',
              },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              width: '25%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'user-level-status-active',
              width: '10%',
              scopedSlots: {
                customRender: 'status',
              },
            },
            {
              title: 'Point',
              dataIndex: 'StrPoint',
              key: 'plan-reward-point-burn-manage-point-unit',
              width: '10%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LevelName',
              dataIndex: 'LevelName',
              key: 'imp-usr-level-header',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
          ]
        } else if (!hasVerifyCode4 && !hasVerifyCode3) {
          this.columnsDetail = [
            {
              title: 'Status',
              dataIndex: 'Success',
              key: 'import-log-status-header',
              width: '20%',
              scopedSlots: {
                customRender: 'is_success',
              },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LastName',
              dataIndex: 'LastName',
              key: 'import-log-lastname-header',
              width: '20%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'VerifyCode2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'BirthDate',
              dataIndex: 'BirthDate',
              key: 'imp-usr-dtl-birthday',
              width: '15%',
              scopedSlots: {
                customRender: 'date',
              },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              width: '10%',
              scopedSlots: {
                customRender: 'gender',
              },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              width: '25%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'user-level-status-active',
              width: '10%',
              scopedSlots: {
                customRender: 'status',
              },
            },
            {
              title: 'Point',
              dataIndex: 'StrPoint',
              key: 'plan-reward-point-burn-manage-point-unit',
              width: '10%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'LevelName',
              dataIndex: 'LevelName',
              key: 'imp-usr-level-header',
              width: '15%',
              scopedSlots: {
                customRender: 'isdata',
              },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
          ]
        }

        this.setLocale()
      }
    },
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'import_member_page',
      'import_member_activity_log',
      'view_import_member_activity_log',
      'on view',
    )
    this.getLogImportUser(0)
    this.getCRMPlusProfile().then(result => {
      if (typeof result.IsAlreadyPoint == 'boolean') {
        this.isAlreadyPoint = result.IsAlreadyPoint
      }
      if (typeof result.IsAlreadyStamp == 'boolean') {
        this.isAlreadyStamp = result.IsAlreadyStamp
      }
      if (typeof result.AllowImportPoint == 'boolean') {
        this.allowImportPoint = result.AllowImportPoint
      }
      if (this.isAlreadyPoint) {
        this.campaignmode = this.lbl['import-mode-point']
      } else {
        this.campaignmode = this.lbl['import-mode-stamp']
      }
    })
    this.setLocale()
  },
  methods: {
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
      _.forEach(this.columnsDetail, column => {
        column.title = this.lbl[column.key]
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    getLogImportUser() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getLogImportUser(0)
          .then(res => {
            this.handleLoading(false)
            console.log('getLogImportUser', res.data)
            this.total = res.data.Count
            this.data = res.data.Result
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLogImportUser error', error)
            resolve(error)
          })
      })
    },
    getLogImportUserDetail() {
      this.handleLoading(true)
      this.showFilterMain = false
      let active = null
      if (this.filter.status.value === 'success') active = true
      if (this.filter.status.value === 'failed') active = false
      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})
      return new Promise(resolve => {
        BzbsImport.getLogImportUserDetail(this.partionkey, this.FileName, 0)
          .then(res => {
            this.handleLoading(false)

            res.data.Result = res.data.Result.map(x => {
              return {
                VerifyCode1: x.VerifyCode1,
                VerifyCode2: x.VerifyCode2,
                VerifyCode3: x.VerifyCode3,
                VerifyCode4: x.VerifyCode4,
                FirstName: x.FirstName,
                LastName: x.LastName,
                BirthDate: x.BirthDate ? x.BirthDate : x.StrBirthDate,
                Gender: x.Gender,
                Email: x.Email,
                Tel: x.Tel,
                Active: x.Active,
                Point: x.Point,
                StrPoint: x.StrPoint,
                LevelName: x.LevelName,
                LevelValue: x.LevelValue,
                ImportUserId: x.ImportUserId,
                Success: x.Success,
                Message: x.Message,
                Type: x.Type,
                PartitionKey: x.PartitionKey,
                RowKey: x.RowKey,
                Timestamp: x.Timestamp,
                ETag: x.ETag,
                _id: x._id,
              }
            })
            if (active != null) {
              this.dataDetail = _.filter(res.data.Result, item => {
                if (item.Success == active) return item
              })
              this.totalDetail = this.dataDetail.length
            } else {
              this.dataDetail = res.data.Result
              this.totalDetail = res.data.Count
            }
            this.dataDetailExport = res.data.Result
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLogImportUserDetail error', error)
            resolve(error)
          })
      })
    },
    onChangePage(page) {
      this.current = page
    },
    onChangePageDetail(page) {
      this.currentDetail = page
    },
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a class="ant-pagination-item-link"> &#60;&#60; </a>
      } else if (type === 'next') {
        return <a class="ant-pagination-item-link"> &#62;&#62; </a>
      }
      return originalElement
    },
    openDetail(record) {
      this.partionkey = record.PartitionKey + '|' + record.RowKey
      this.FileName = record.FileName
      this.filter.status.value = ''
      this.getLogImportUserDetail(0)
      this.$nextTick(() => {
        this.visibleDetail = true
      })
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'success' &&
          item === 'success') ||
        (this.filter[this.subFilter].value === 'failed' && item === 'failed')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.getLogImportUserDetail()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.getLogImportUserDetail()
    },

    //onlyNumber
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    onlyNumberDetail($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    goto(page) {
      if (page == 'ImportUser') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member',
          'click_import_member',
          'on click',
        )
      } else if (page == 'ImportUserSearch') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member',
          'click_import_member_search_imported',
          'on click',
        )
      }
      this.$router.push({ name: page })
    },
    exportFile(type) {
      console.log('exportFile type : ', type)
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member_activity_log_' + type,
        'clickimport_member_activity_log',
        'on click',
      )

      var resultData
      if (type == 'success') {
        resultData = this.dataDetailExport.filter(item => !item.Message)
      } else if (type == 'fail') {
        resultData = this.dataDetailExport.filter(item => item.Message)
      }
      let data = _.map(resultData, item => {
        let bd = null
        if (
          item.BirthDate != null &&
          item.BirthDate != '-' &&
          item.BirthDate != ''
        ) {
          bd = this.formatDateExport(item.BirthDate)
        } else {
          bd = null
        }

        var verifycode1 = 'verifycode1'
        var verifycode2 = 'verifycode2'
        var name = 'name'
        var lastname = 'lastname'
        var birthday = 'birthday'
        var gender = 'gender'
        var email = 'e-mail'
        var tel_number = 'tel number'
        var active = 'active'
        var point = 'point'
        var userlevel = 'userlevel'
        var remark = 'remark'

        return {
          [verifycode1]: item.VerifyCode1 ? item.VerifyCode1 : '',
          [verifycode2]: item.VerifyCode2 ? item.VerifyCode2 : '',
          [name]: item.FirstName ? item.FirstName : '',
          [lastname]: item.LastName ? item.LastName : '',
          [birthday]: bd ? bd : '',
          [gender]: item.Gender ? item.Gender : '',
          [email]: item.Email ? item.Email : '',
          [tel_number]: item.Tel ? item.Tel : '',
          [active]: item.Active,
          [point]: item.StrPoint ? item.StrPoint : '',
          [userlevel]: item.LevelName ? item.LevelName : '',
          [remark]: item.Message ? item.Message : '',
        }
      })

      var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
      var wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 12 },
        { wch: 12 },
        { wch: 20 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
      ]
      dataWS['!cols'] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(
        wb,
        'CRMPlus_import_company_user_' +
          moment(new Date()).format('YYYY-MM-DD') +
          '.xlsx',
      )

      this.handleLoading(false)
    },
    formatDate(time) {
      if (typeof time == 'number') {
        return moment.unix(time).format('DD/MMM/YYYY')
      } else {
        try {
          var unix = moment(time, 'X')
          return moment.unix(unix).format('DD/MMM/YYYY')
        } catch {
          return ''
        }
      }
    },
    formatDateExport(time) {
      if (typeof time == 'number') {
        return moment.unix(time).format('DD/MM/YYYY')
      } else {
        try {
          var unix = moment(time, 'X')
          return moment.unix(unix).format('DD/MM/YYYY')
        } catch {
          return ''
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/common.scss';

.wrap-importUserLog {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  background: #fff;
  .wrap-table {
    width: 75%;
    margin: 15px auto 0;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }

    .ant-btn-group {
      display: inline-grid;
    }

    .ant-btn-group .ant-btn {
      border-style: solid !important;
      border-color: #6c757d !important;
      border-radius: 0 !important;
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss">
@import '@/style/base/common.scss';

.table-detail-importUserLog {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }
  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }

  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .ant-table-pagination.ant-pagination {
      margin: 16px 0;
    }
  }

  //แนวตั้ง iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: portrait) {
    .ant-modal {
      width: 80% !important;
    }
  }

  //แนวนอน iPad
  @media only screen and (min-device-width: 767px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ant-modal {
      width: 80% !important;
    }
  }
}
</style>
<style lang="scss">
@import '../ImportBatchPoint/AdjustPoint.scss';
@import '@/style/base/common.scss';

.around-content-import {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
@import '../ImportBatchPoint/AdjustPointScoped.scss';
.around-content-import {
  padding-top: 30px !important;
}
.custom-wrapper-dashboard {
  padding: 16px 30px;
}
.wrapper-select-header {
  padding: 0 30px !important;
}
.around-content-import {
  padding: 14px 30px !important;
}
.export-file-container {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 24px;

  .btn-fail,
  .btn-success {
    padding: 0px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
    height: 40px;
    align-items: center;

    img {
      height: 24px;
      width: 24px;
    }
  }

  .btn-success {
    border: solid 1px #4caf50;
    background-color: #ffffff;
    color: #4caf50;
    &:hover {
      border: solid 1px #4caf50;
      background-color: #e7ffe8;
    }
  }
  .btn-fail {
    border: solid 1px #ff4d4f;
    background-color: #ffffff;
    color: #ff4d4f;
    &:hover {
      border: solid 1px #ff4d4f;
      background-color: #ffeeee;
    }
  }
}

//แนวตั้ง mobile
@media only screen and (max-device-width: 767px) and (orientation: portrait) {
  .export-file-container {
    display: block;
  }
}

//แนวนอน mobile
@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .export-file-container {
    display: block;
  }
}
</style>

<style lang="scss">
#modal-import-log-detail {
  .filterContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .btn-permission {
      width: 157px;
      height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: solid 1px #f9a601;
      background-color: #fff;
      color: #f9a601;
      font-size: 16px;
    }
  }
  .filter,
  .filterItem {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px #e0e0e0;
    cursor: pointer;
    color: #616161;
    font-size: 16px;
    font-weight: 500;
    img {
      margin-left: 8px;
    }
  }
  .filterMain {
    position: relative;
    &.active {
      .filterDropdown {
        display: flex;
      }
    }
  }
  .filterDropdown {
    z-index: 3;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    top: 52px;
    position: absolute;
    background-color: #fff;
    display: none;
    padding: 0 0 64px 0;
    font-size: 16px;
    color: #212529;
    .filterLeft {
      width: 250px;
      border-right: 1px solid #e0e0e0;
      @media only screen and (max-width: 460px) {
        width: auto;
      }
    }
    .filterRight {
      width: 250px;
      @media only screen and (max-width: 460px) {
        width: auto;
      }
      &:hover {
        display: block;
      }
      .filterList {
        justify-content: flex-start;
        input {
          cursor: pointer;
        }
        &.input {
          input {
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 5px;
            cursor: auto;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .filterList {
      height: 42px;
      width: 100%;
      padding: 0 24px;
      border-bottom: 1px solid #e0e0e0;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: #fff3e0;
      }
      &:hover {
        background-color: #fff3e0;
      }
      text-align: left;
    }
  }
  .btnFilter {
    width: 100%;
    position: absolute;
    bottom: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: blue;
    div {
      cursor: pointer;
    }
    button {
      align-items: center;
      padding: 6px 18px;
      border-radius: 4px;
      background-color: #ff9800;
      color: #fff;
      border: none;
    }
  }
  .roleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    margin: 24px 0 0 0;
    .roleItem {
      width: 410px;
      @media only screen and (max-width: 550px) {
        width: 100%;
      }
      flex-grow: 1;
      .roleHeader {
        // min-height: 120px;
        border: 1px solid #e0e0e0;
        border-bottom: none;
        border-radius: 5px 5px 0 0;
        padding: 16px;
        .headerTab {
          display: flex;
          justify-content: space-between;
          button {
            width: 56px;
            height: 35px;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            border-radius: 5px;
          }
        }
        .textTitle {
          font-size: 20px;
        }
        .chip {
          margin: 0 0 0 8px;
        }
        .roleSubHeader {
          margin-top: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 62px;
          position: relative;
          margin-bottom: 0;
          min-height: 44px;
          &.showmore {
            -webkit-line-clamp: unset;
          }
          span {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #2196f3;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .roleBody {
        height: 449px;
        border: 1px solid #e0e0e0;
        border-radius: 0 0 5px 5px;
        background-color: #fafafa;
        .sortByContainer {
          height: 56px;
          padding: 16px;
          color: #bdbdbd;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e0e0e0;
          .sortFilter {
            &:hover {
              .sortDropdown {
                display: block;
                color: black;
              }
            }
            cursor: pointer;
            position: relative;
            .sortDropdown {
              display: none;
              border-radius: 5px;
              border: 1px solid #e0e0e0;
              width: 124px;
              position: absolute;
              right: 0;
              background-color: white;
              div:first-child {
                border-bottom: 1px solid #e0e0e0;
              }
              div {
                text-align: center;
                padding: 6px;
                cursor: pointer;
                &:hover {
                  background-color: #fff3e0;
                }
              }
            }
          }
        }
        .userListContainer {
          overflow-y: scroll;
          height: calc(100% - 56px);
          /* width */
          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .userList {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            background-color: white;
            gap: 22px;
            padding: 16px;
            border-bottom: 1px solid #e0e0e0;
            @media only screen and (max-width: 460px) {
              padding: 16px 4px;
              flex-wrap: wrap;
              gap: 22px;
              justify-content: center;
            }
            .userStatus {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 64px;
              height: 32px;
              font-weight: 500;
              border-radius: 24px;
              &.active {
                color: #4caf50;
                background-color: #4caf5033;
              }
              &.inactive {
                color: #ff5252;
                background-color: #ff525233;
              }
            }
            .detail {
              text-align: left;
              margin-right: auto;
              margin-left: auto;
              @media only screen and (max-width: 460px) {
                width: 100%;
                text-align: center;
              }
              .userEmail {
                color: #bdbdbd;
                white-space: nowrap;
                width: 145px;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .userName {
                font-size: 16px;
                font-weight: 600;
                margin: 5px auto 0 auto;
                color: #616161;
                white-space: nowrap;
                width: 145px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .action {
              margin-left: auto;
              display: flex;
              gap: 8px;
              @media only screen and (max-width: 460px) {
                margin-left: 0;
              }
              > * {
                width: 56px;
                height: 35px;
                color: #2196f3;
                font-size: 16px;
                font-weight: 500;
                text-decoration: underline;
                border-radius: 5px;
                border: 1px solid #e0e0e0;
                background-color: white;
              }
              .copy {
                border-color: #1e88e51a;
              }
              .edit {
                border-color: #bdbdbd;
              }
            }
          }
        }
      }
    }
  }
}
</style>
